import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { AntDesign, MaterialIcons, Ionicons } from '@expo/vector-icons';
import { ASSET_TYPE, INSTANCE_TYPES } from '@src/constants';
import { useTranslation } from 'react-i18next';
import { font, theme } from '@src/styles';
import { useScreen } from '@src/hooks/useScreen';
import { Pair } from './Pair';

export const STATUSES = {
    IDENTIFIED: 'identified',
    NOT_FOUND: 'not_found',
    ERROR: 'error',
    PAIRED: 'paired',
    PAIRED_SUCCESS: 'paired_success',
    REMOVED_SUCCESS: 'removed_success',
    ASSET_EXISTS: 'asset_existis',
    WRONG_ASSET_TYPE: 'wrong_asset_type',
    LOGGER_TYPE_SELECT: 'logger_type_select',
};

export const STATUS_ICONS = {
    DONE: 'done',
};

type Props = {
    status: string,
    instanceType?: 'asset' | 'logger',
    assetNumber?: number,
    loggerNumber?: number,
    assetType? : string,
    iconStatus? : string,
    deviderTextCode? : string,
    pairStyle? : object,
};

export const Status = ({
    status,
    assetNumber,
    loggerNumber,
    instanceType,
    assetType,
    iconStatus,
    deviderTextCode,
    pairStyle = {},
}: Props) => {
    const { t } = useTranslation();
    const { isLandscape, wp, hp } = useScreen();

    const iconSize = isLandscape ? hp('7.1%') : 48;

    const styles = StyleSheet.create({
        icon: {
            marginRight: 5,
            ...(isLandscape && {
                marginRight: wp('0.9%'),
            }),
        },
        pairedWarningIcon: {
            marginRight: 16,
            ...(isLandscape && {
                marginRight: wp('0.9%'),
            }),
        },
        subTitle: {
            ...font.normal,
            color: theme.palette.common.black,
            fontSize: 16,
            letterSpacing: 0.18,
            lineHeight: 24,
            marginTop: 16,
            paddingLeft: 55,
            ...(isLandscape && {
                paddingLeft: wp('4.4%'),
            }),
        },
        title: {
            ...font.normal,
            color: theme.palette.common.black,
            fontSize: 22,
            letterSpacing: 0.18,
            lineHeight: 24,
            maxWidth: '95%',
            ...(isLandscape && {
                fontSize: hp('3.5%'),
                lineHeight: hp('4.4%'),
            }),
        },
        titleErrorMargin: {
            marginLeft: 10,
        },
        titleNotFoundWrapper: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            maxWidth: '80%',
        },
        wrapper: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        wrapperError: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            width: '100%',
        },
        wrapperNotFound: {
            alignSelf: 'center',
        },
        wrapperPaired: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
        },
        wrapperPairedMargin: {
            marginBottom: 46,
            ...(isLandscape && {
                marginBottom: hp('3.5%'),
            }),
        },
    });

    if (status === STATUSES.IDENTIFIED) {
        const getMessage = () => {
            if (instanceType === INSTANCE_TYPES.LOGGER) {
                return t('ADD_LOGGER.LOGGER_IDENTIFIED');
            } else if (assetType === ASSET_TYPE.PALLET) {
                return t('ADD_LOGGER.PALLET_IDENTIFIED');
            } else {
                return t('ADD_LOGGER.CONTAINER_IDENTIFIED');
            }
        };

        return (
            <View style={styles.wrapper}>
                <MaterialIcons name="done" size={iconSize} style={styles.icon} color={theme.palette.primary.deepBlue} />
                <Text style={styles.title}>
                    {getMessage()}
                </Text>

            </View>
        );
    }

    if (status === STATUSES.NOT_FOUND) {
        return (
            <View style={[styles.wrapper, styles.wrapperNotFound]}>
                <AntDesign
                    name="close"
                    size={iconSize}
                    style={styles.icon}
                    color={theme.palette.common.red}
                />
                <View style={styles.titleNotFoundWrapper}>
                    <Text style={styles.title}>
                        {instanceType === INSTANCE_TYPES.ASSET
                            ? t('COMMON.PALLET_OR_CONTAINER')
                            : instanceType === INSTANCE_TYPES.LOGGER ? t('COMMON.LOGGER') : t('COMMON.NUMBER')}
                        {' '}
                    </Text>
                    <Text style={styles.title}>{t('COMMON.NOT_FOUND')}</Text>
                    <Text style={styles.title}>{t('COMMON.IN_THE_SYSTEM')}</Text>
                </View>
            </View>
        );
    }

    if (status === STATUSES.ASSET_EXISTS) {
        return (
            <View style={styles.wrapper}>
                <AntDesign name="close" size={iconSize} style={styles.icon} color={theme.palette.common.red} />
                <Text style={styles.title}>
                    {instanceType === INSTANCE_TYPES.ASSET ? t('COMMON.PALLET_OR_CONTAINER') : t('COMMON.LOGGER')}
                    {' '}
                    {t('COMMON.ALREADY_EXISTS')}
                    .
                </Text>
            </View>
        );
    }

    if (status === STATUSES.PAIRED) {
        return (
            <View>
                <View style={[styles.wrapperPaired, styles.wrapperPairedMargin]}>
                    {iconStatus === STATUS_ICONS.DONE
                        ? (
                            <MaterialIcons
                                name="done"
                                size={iconSize}
                                style={styles.icon}
                                color={theme.palette.primary.deepBlue}
                            />
                        )
                        : (
                            <Ionicons
                                name="warning-outline"
                                size={iconSize}
                                style={[styles.icon, styles.pairedWarningIcon]}
                                color={theme.palette.common.yellow}
                            />
                        )}
                    <Text style={styles.title}>
                        {t('ADD_LOGGER.PAIRING_IDENTIFIED')}
                    </Text>
                </View>

                <Pair
                    loggerNumber={loggerNumber}
                    assetNumber={assetNumber}
                    assetType={assetType}
                    style={pairStyle}
                />
            </View>
        );
    }

    if (status === STATUSES.PAIRED_SUCCESS) {
        return (
            <Pair
                loggerNumber={loggerNumber}
                assetNumber={assetNumber}
                assetType={assetType}
                isSuccess
            />
        );
    }

    if (status === STATUSES.REMOVED_SUCCESS) {
        return (
            <Pair
                loggerNumber={loggerNumber}
                assetNumber={assetNumber}
                assetType={assetType}
                deviderTextCode={deviderTextCode}
                isSuccess
            />
        );
    }

    if (status === STATUSES.ERROR) {
        return (
            <View>
                <View style={styles.wrapperError}>
                    <AntDesign
                        name="exclamationcircleo"
                        size={iconSize}
                        style={styles.icon}
                        color={theme.palette.common.red}
                    />
                    <Text style={[styles.title, styles.titleErrorMargin]}>
                        {t('COMMON.ERROR')}
                    </Text>
                </View>
                <Text style={styles.subTitle}>
                    {t('COMMON.ERROR_DESCRIPTION')}
                </Text>
            </View>
        );
    }

    return null;
};
